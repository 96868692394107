.booking-wrapper {
  width: 100%;
  padding: 0 8px;
}

.new-booking-type-body {
  color: var(--primary-text-color);
  width: 900px;
  height: 500px;
  background-color: var(--booking-background);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';

  display: flex;
  flex-direction: column;

  .label {
    font-size: 24px;
    font-weight: bold;
    margin: 32px 0px;
  }

  .separator {
    width: 5px;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 2px 0px #dadada;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
    height: 130px;
    border-radius: 5px;
    background-color: var(--background-3);
    color: var(--color-2);
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: bold;
    margin: 16px 0px;

    .left-icon {
    }

    .sub-label {
      margin: 0px 16px;
      color: var(--secondary-text-color);
    }

    .right-icon {
      cursor: pointer;
    }
  }

  @media screen and(max-width: 1024px) {
    width: 94vw;
  }
}

.new-booking-add-misc-note {
  font-size: 14px;
  cursor: pointer;
}

.new-one-time-booking-body {
  width: 100%;
  background-color: var(--booking-background);
  min-height: 570px;
  border-radius: 15px;
  padding: 16px 32px;
  font-size: 12px;
  font-family: 'Poppins-Medium';
  color: var(--primary-text-color);

  form {
    width: 100%;

    input::placeholder {
      font-size: 12px;
      color: gray;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 16px;

    .column {
      flex: 1;
      &.fix-content {
        flex: none;
      }

      &.onetime-date {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;

        .fix-content {
          flex: none;

          .label-input {
            width: 100%;
            margin-bottom: 4px;

            .ant-select-selector {
              height: 43px;
              align-items: center;
              font-size: 12px;
            }
            .ant-picker-input {
              input {
                font-size: 12px;
                height: 41px;
                border: none;
              }
            }
          }
        }
      }

      .row {
        margin-bottom: 0px;
      }

      .label-input {
        display: flex;
        flex-direction: column;
        color: var(--secondary-text-color);

        label {
          color: var(--primary-text-color);

          margin-bottom: 4px;
          padding-left: 2px;
        }

        input,
        select,
        .ant-select-selector {
          border-radius: 5px;
          padding: 5px 10px;
          min-height: 45px;
          width: 100%;
        }

        textarea {
          border-radius: 5px;
          padding: 5px 10px;
          overflow-y: auto;
        }

        .date-time-picker {
          input {
            height: 30px;
            background-color: var(--background-3);
          }
        }
      }

      .phone-detail {
        display: flex;
        gap: 8px;

        &-code {
          width: 120px;
        }

        &-number {
          width: 100%;
        }
      }

      .add-more-service {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 1024px) {
        &.onetime-date {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  .onetime-action {
    display: flex;
    justify-content: space-between;
  }

  .action-button {
    margin-left: 0px;
    align-items: center;
    gap: 32px;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  .link-button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    text-decoration: underline;
    align-items: center;
    color: var(--primary-text-color);

    span {
      cursor: default;
    }

    .delete {
      margin-left: 4px;
      width: 16px;
    }
  }

  .multi-service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 16px;
      text-decoration: underline;
    }
  }
}

.new-recurring-booking-body {
  width: 100%;
  background-color: var(--booking-background);
  min-height: 570px;
  border-radius: 15px;
  padding: 16px 32px;
  color: var(--primary-text-color);
  font-size: 12px;
  font-family: 'Poppins-Medium';
  flex: 1;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;
  }

  .services {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 16px;
      text-decoration: underline;
      margin-top: 12px;
    }
  }

  .quote {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 16px;
      text-decoration: underline;
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 16px;

    &.header {
      background-color: var(--table-header-color-1);
      color: var(--table-text-color);
      margin-bottom: unset;
      height: 30px;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      padding: 4px;
    }

    &.body {
      flex-direction: column;

      background-color: var(--background-3);
      color: var(--table-body-text-1);

      margin-bottom: unset;
      height: auto;
      align-items: center;
      font-size: 10px;
      padding: 4px;
      gap: 4px;

      .row {
        margin-bottom: unset;
      }
    }

    .column {
      &.f1 {
        flex: 1;
      }

      &.f2 {
        flex: 2;
      }

      &.f3 {
        flex: 3;
      }

      flex: 1;

      &.fix-content {
        flex: none;

        .ant-select-selector {
          min-width: 160px;
          max-width: 160px;
          height: 36px;
          align-items: center;
          font-size: 12px;
        }

        .label-input {
          width: fit-content;
        }
      }

      .row {
        margin-bottom: 0px;
      }

      .label-input {
        display: flex;
        flex-direction: column;
        color: var(--secondary-text-color);

        label {
          color: var(--primary-text-color);

          margin-bottom: 4px;
          padding-left: 2px;
        }

        input,
        select,
        .ant-select-selector {
          border-radius: 5px;
          padding: 5px 10px;
          height: 45px;
          width: 100%;
        }
      }

      .phone-detail {
        display: flex;
        gap: 8px;

        &-code {
          width: 120px;
        }

        &-number {
          width: 100%;
        }
      }

      .add-more-service {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-top: 36px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .action-button {
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    &__control-quote {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 12px;
    }

    &__control-booking {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  .quote-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .quote-status {
      margin-left: 4px;

      > span {
        margin-left: 4px;
      }

      .quote-accepted {
        width: 20px;
        height: 20px;
      }

      .quote-rejected {
        width: 20px;
        height: 20px;
      }
    }
  }

  .appointment-booking {
    margin-top: 24px;

    .note {
      font-size: 12px;
    }

    .booked-label {
      font-size: 16px;
    }

    .admin-2-plan {
      display: flex;
      flex-direction: column;
      width: 100%;

      & label {
        font-size: 14px;
        margin: 4px 0px;
      }

      .admin-plan-label {
        font-size: 16px;
      }
    }

    .times {
      .row {
        justify-content: unset;
      }
    }

    .label-input {
      label {
        font-size: 12px;
      }

      div {
        display: flex;
        background-color: var(--background-3);
        color: var(--secondary-text-color);
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 150px;
      }
    }

    .customer-preference {
      margin-top: 16px;
      padding: 16px;
      background-color: var(--background-2);
      color: var(--primary-text-color);
      font-size: 12px;

      .row {
        justify-content: unset;
        margin: 8px 0px;
      }

      .title {
        font-size: 20px;
        font-weight: bold;

        margin-bottom: 16px;
      }

      .preference-title {
        width: 120px;
      }

      .preference-box {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--background-3);
        color: var(--secondary-text-color);
        height: 30px;
        width: 200px;
        border-radius: 4px;
      }
    }

    .technician-assign {
      color: var(--primary-text-color);
      font-size: 12px;

      .row {
        justify-content: unset;
        margin: 8px 0px;
        align-items: center;
        padding: 2px;
      }

      .row.error {
        background-color: rgba(red, 0.5);
        border: 1px solid red;
      }

      .preference-title {
        width: 120px;
      }

      .react-date-picker {
        background-color: var(--background-3);
        border-radius: 5px;
        color: var(--secondary-text-color);
        padding: 4px;
        height: 36px;

        .react-date-picker__wrapper {
          border: none;
          align-items: center;
          justify-content: center;
          border-radius: unset;
        }
      }

      .react-timerange-picker {
        background-color: var(--background-3);
        border-radius: 5px;
        color: var(--color-1);

        .react-timerange-picker__wrapper {
          border: none;
          background-color: var(--background-3);
          height: 36px;
          align-items: center;
          border-radius: 5px;

          .react-timerange-picker__inputGroup {
            height: unset;
          }
        }
      }

      .ant-select {
        display: block;
        height: 36px;
      }
    }

    .invvoice-section {
      cursor: pointer;

      > span {
        margin-left: 4px;
      }
    }
  }

  .promo-code-used {
    color: var(--primary-text-color);
  }

  .tasks-reassign {
    margin-top: 8px;
  }
}

.new-one-time-booking-body,
.new-recurring-booking-body {
  .reassign-needed {
    border: 2px solid #c16c6e !important;
    border-radius: 4px;

    .Dropdown-control {
      background: #fee6e9;

      .Dropdown-placeholder {
        color: #c16c6e;
      }
    }
  }
}
